$white:         #fff         !default;
$gray-100:      #f8f9fa      !default;
$gray-200:      #e9ecef      !default;
$gray-300:      #dee2e6      !default;
$gray-400:      #ced4da      !default;
$gray-500:      #adb5bd      !default;
$gray-600:      #6c757d      !default;
$gray-700:      #495057      !default;
$gray-800:      #343a40      !default;
$gray-900:      #212529      !default;
$black:         #000         !default;

$light-purple:  #6d2077      !default;
$violet:        #483498      !default;

$blue:          #00338D      !default;
$medium-blue:   #005eb8      !default;
$indigo:        #470A68      !default;
$purple:        #6D2077      !default;
$pink:          #C6007E      !default;
$red:           #BC204B      !default;
$orange:        #F68D2E      !default;
$yellow:        #EAAA00      !default;
$green:         #009A44      !default;
$teal:          #00A3A1      !default;
$cyan:          #0091DA      !default;

$primary:       $blue        !default;
$secondary:     $medium-blue !default;
$success:       $green       !default;
$info:          $teal        !default;
$warning:       $yellow      !default;
$danger:        $red         !default;

$small-font-size: 70%        !default;
$list-group-color:           black;
