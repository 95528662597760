@import 'styles/styles';

button:focus, button:active:focus, button.active:focus {
  outline: none !important;
  outline-style: none !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .5s linear infinite;
}

table {
  width: 100%;
}

// Primary
.kpmg-blue {
  background: #00338d;
  color: white;
}

.kpmg-medium {
  background: #005eb8;
  color: white;
}

.kpmg-light {
  background: #0091da;
  color: white;
}

// Secondary
.kpmg-violet {
  background: #483698;
  color: white;
}

.kpmg-purple {
  background: #470a68;
  color: white;
}

.kpmg-green {
  background: #00a3a1;
  color: white;
}

// Tretiary
.kpmg-dark-green {
  background: #009a44;
  color: white;
}

.kpmg-light-green {
  background: #43b02a;
  color: white;
}

.kpmg-yellow {
  background: #eaaa00;
  color: white;
}

.kpmg-orange {
  background: #f68d2e;
  color: white;
}

.kpmg-green {
  background: #009a44;
  color: white;
}

.kpmg-red {
  background: #bc204b;
  color: white;
}

.kpmg-pink {
  background: #c6007e;
  color: white;
}

.error-snackbar {
  @extend .kpmg-red
}

.success-snackbar {
  @extend .kpmg-green
}

.inline {
  display: inline-block !important;
  margin-right: 20px !important;
}

.center-auto {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container {
  width: 100%;
  //height: calc(100vh - 250px);
  overflow: auto;
}

.table-container-without-paginator {
  width: 100%;
  //height: calc(100vh - 350px);
  overflow: auto;
}

.create-doc-table-container {
  width: 100%;
  //height: calc(100vh - 350px);
  overflow: auto;
}

.create-doc-table-container-without-paginator {
  width: 100%;
  //height: calc(100vh - 350px + 56px); // paginator height 56px is
  overflow: auto;
}
