@import '../variables';
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import 'reboot';

.btn-xs {
  padding: 0.125rem .15rem;
  font-size: .8rem;
  line-height: .2;
}

.btn-xxs {
  height: 15px;
  padding: 0.1rem;
  font-size: 0.55rem;
}

@each $spacer, $gap in $spacers {
  .gap-#{$spacer} { gap: $gap; }
}
