.ant-modal-close {
  right: 5px;
  top: 5px;

  &-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

.ant-modal-header {
  background-color: #f8f9fa;
}
