@import '~node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '_calendar';
@import '_divider';
@import '_modal';
@import '_select';
@import '_table';
@import '_variables';
@import '_form';

.anticon { vertical-align: 2px }

// Breadcrumb
// ---
@breadcrumb-font-size: 24px;
@breadcrumb-separator-margin: 0;
